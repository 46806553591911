import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Message = forwardRef(({
  className,
  delay,
}, ref) => {
  const [message, setMessage] = useState('');
  const messageTimeout = useRef(null);

  useImperativeHandle(ref, () => ({
    setMessage(text) {
      clearTimeout(messageTimeout.current);
      setMessage(text);
      messageTimeout.current = setTimeout(() => {
        setMessage('');
      }, delay);
    },
  }), []);

  return !message ? null : (
    <div className={className}>{message}</div>
  );
});

Message.propTypes = {
  className: PropTypes.string,
  delay: PropTypes.number,
};

Message.defaultProps = {
  className: '',
  delay: 3000,
};

export default styled(Message)``;
