import OffscreenCanvas from '../lib/OffscreenCanvas';

const DEFAULT_ALIVE_COLORS = [
  0xff0000ff, // red
  0xff00ffff, // pink
  0x00ee00ff, // light green
  0x3300ffff, // blue
  0x00aa00ff, // green
];

const DEFAULT_DEAD_COLORS = [
  0x000000ff, // black
];

const COLOR_SWATCH_SIZE = 10;

// state (S): 1 = dead, 0 = alive
// age (A): number of generations the cell has been in the current state
//                       xxxxxxxxxxxxxxxxAAAAAAAAxxxxxxxS
const BITMASK_UNUSED = 0b11111111111111110000000011111110;

export function createColorAutomaton(aliveColors = DEFAULT_ALIVE_COLORS, deadColors = DEFAULT_DEAD_COLORS) {
  const numAliveColors = aliveColors.length;
  const numDeadColors = deadColors.length;

  // Conversion between a display value and a elementary cellular automaton bit
  const bitToValue = (bit, bitPrev, valuePrev = 0) => {
    const state = bit & 0b1;
    const age = valuePrev == null || bit !== bitPrev
      ? 0
      : ((valuePrev >>> 8) + 1) % (state ? numAliveColors : numDeadColors);

    return (
      (valuePrev == null ? 0 : BITMASK_UNUSED & valuePrev)
      | ((age & 0b11111111) << 8)
      | state
    );
  };
  const valueToBit = value => value & 0b1;

  // Create the tile canvas
  const width = COLOR_SWATCH_SIZE * Math.max(numAliveColors, numDeadColors);
  const height = COLOR_SWATCH_SIZE * 2;
  const tileCanvas = new OffscreenCanvas(width, height);
  const ctx = tileCanvas.getContext('2d');

  deadColors.forEach((color, i) => {
    ctx.fillStyle = `#${color.toString(16).padStart(8, '0')}`;
    const x = COLOR_SWATCH_SIZE * i;
    const y = 0;
    ctx.fillRect(x, y, COLOR_SWATCH_SIZE, COLOR_SWATCH_SIZE);
  });

  aliveColors.forEach((color, i) => {
    ctx.fillStyle = `#${color.toString(16).padStart(8, '0')}`;
    const x = COLOR_SWATCH_SIZE * i;
    const y = COLOR_SWATCH_SIZE;
    ctx.fillRect(x, y, COLOR_SWATCH_SIZE, COLOR_SWATCH_SIZE);
  });

  // Get the source tile from the canvas for a given value
  const getCanvasSource = value => [
    COLOR_SWATCH_SIZE * ((value >>> 8) & 0b11111111),
    COLOR_SWATCH_SIZE * (value & 0b1),
    COLOR_SWATCH_SIZE,
    COLOR_SWATCH_SIZE,
  ];

  // Compare two values to determine whether the tile should rerender
  const shouldRender = () => true;

  // Start/stop asynchronous behavior
  const start = () => {};
  const stop = () => {};
  const reset = () => {};
  const special = (stateRef) => {
    const { data, row, width: cellWidth } = stateRef.current;
    const end = Math.min((row + 1) * cellWidth, data.length);
    for (let i = row * cellWidth; i < end; i++) {
      const valuePrev = data[i];
      const bitPrev = valueToBit(valuePrev);
      const bit = !bitPrev;
      // eslint-disable-next-line no-param-reassign
      data[i] = bitToValue(bit, bitPrev, valuePrev);
    }
  };

  return {
    viewportScale: 50,
    minSize: 2,
    maxSize: 20,
    bitToValue,
    valueToBit,
    tileCanvas,
    getCanvasSource,
    shouldRender,
    start,
    stop,
    reset,
    special,
  };
}

export default createColorAutomaton();
