import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import {
  useCallback,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import produce from 'immer';

export function useWrappedCallback(func, wrapper, wrapperArgs = [], dependencies = [func]) {
  const funcBody = useRef(func);
  useLayoutEffect(() => { funcBody.current = func; }, dependencies);

  const wrappedCallback = useRef(() => {
    throw new Error('Cannot invoke wrapped callback while rendering.');
  });

  useLayoutEffect(() => {
    wrappedCallback.current = wrapper(function fn(...args) {
      return funcBody.current.apply(this, args);
    }, wrapperArgs);

    return () => {
      if (wrappedCallback.current) {
        wrappedCallback.current.cancel();
      }
    };
  }, [funcBody, wrappedCallback, wrappedCallback, wrapper, ...wrapperArgs]);

  return useMemo(() => (
    function wrapped(...args) {
      return wrappedCallback.current.apply(this, args);
    }
  ), [wrappedCallback]);
}

export function useDebouncedCallback(func, wait, dependencies = [func]) {
  return useWrappedCallback(func, debounce, [wait], dependencies);
}

export function useThrottledCallback(func, wait, dependencies = [func]) {
  return useWrappedCallback(func, throttle, [wait], dependencies);
}

export function useRafInterval(ms, max = Infinity) {
  const [count, set] = useState(0);
  const startTime = useRef(Date.now());

  useLayoutEffect(() => {
    let raf;

    const onFrame = () => {
      const now = Date.now();
      if (now - startTime.current >= ms) {
        if (max && max < Infinity) {
          set(prevCount => (prevCount + 1) % max);
        } else {
          set(prevCount => prevCount + 1);
        }
        startTime.current = now;
      }
      loop(); // eslint-disable-line no-use-before-define
    };
    const loop = () => {
      raf = requestAnimationFrame(onFrame);
    };

    if (ms) {
      loop();
    }

    return () => {
      cancelAnimationFrame(raf);
    };
  }, [ms]);

  return count;
}

export function useWindowSize(throttleAmount = 100) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = useThrottledCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, throttleAmount);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return windowSize;
}

export function useKeyCommands(keyCommands, preKeyCommand = true) {
  const getKeyCommand = useCallback((keyCode, {
    altKey,
    ctrlKey,
    metaKey,
    shiftKey,
  }) => {
    const command = [
      altKey && 'alt',
      ctrlKey && 'ctrl',
      metaKey && 'meta',
      shiftKey && 'shift',
      keyCode,
    ].filter(v => v).join('+');
    return keyCommands[command] || keyCommands[`*+${keyCode}`];
  }, [keyCommands]);

  const handleKeyDown = useCallback((e) => {
    const {
      altKey,
      ctrlKey,
      keyCode,
      metaKey,
      shiftKey,
    } = e;

    const keyCommand = getKeyCommand(keyCode, {
      altKey,
      ctrlKey,
      metaKey,
      shiftKey,
    });

    if (typeof keyCommand === 'function') {
      if (typeof preKeyCommand === 'function') {
        preKeyCommand(e);
      } else if (preKeyCommand === true) {
        e.preventDefault();
      }
      keyCommand(e);
    }
  }, [getKeyCommand, preKeyCommand]);

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
}

export function useFirstTouch(callback, dependencies = [callback]) {
  const [touched, set] = useState(false);

  const handleFirstTouch = useCallback((e) => {
    const result = callback(e);
    if (result !== false) {
      document.removeEventListener('click', handleFirstTouch);
      document.removeEventListener('mousedown', handleFirstTouch);
      document.removeEventListener('touchstart', handleFirstTouch);
      document.removeEventListener('touchend', handleFirstTouch);
      set(true);
    }
  }, dependencies);

  useLayoutEffect(() => {
    document.addEventListener('click', handleFirstTouch);
    document.addEventListener('mousedown', handleFirstTouch, { passive: false });
    document.addEventListener('touchstart', handleFirstTouch, { passive: false });
    document.addEventListener('touchend', handleFirstTouch, { passive: false });
    return () => {
      document.removeEventListener('click', handleFirstTouch);
      document.removeEventListener('mousedown', handleFirstTouch);
      document.removeEventListener('touchstart', handleFirstTouch);
      document.removeEventListener('touchend', handleFirstTouch);
    };
  }, [handleFirstTouch]);

  return touched;
}

function getTouchData(touch) {
  const {
    identifier: id,
    clientX: x,
    clientY: y,
  } = touch;
  return {
    id,
    x,
    y,
  };
}

function calculateMove(x1, y1, x2, y2) {
  const dx = x2 - x1;
  const dy = y2 - y1;
  const distance = Math.sqrt((dx ** 2) + (dy ** 2));
  const angle = Math.atan2(dx, -dy) * 180 / Math.PI;
  return {
    angle,
    distance,
  };
}

export function useTouchEvents(onTouchMove, onTouchEnd, moveThreshold = 10) {
  const touches = useRef({});

  const handleTouchStart = useCallback((e) => {
    const { changedTouches } = e;
    for (let i = 0; i < changedTouches.length; i++) {
      const touch = getTouchData(changedTouches[i]);
      const { id, x: xStart, y: yStart } = touch;
      const { x: xPrev, y: yPrev } = touches.current[id] || {};
      touches.current[id] = {
        xStart,
        yStart,
        xPrev,
        yPrev,
        ...touch,
      };
    }
    e.preventDefault();
  }, []);

  const handleTouchMove = useCallback((e) => {
    const { changedTouches } = e;
    for (let i = 0; i < changedTouches.length; i++) {
      const touch = getTouchData(changedTouches[i]);
      const { id } = touch;
      const touchPrev = touches.current[id] || {};
      const {
        xStart,
        yStart,
        x: xPrev,
        y: yPrev,
      } = touchPrev;
      const { x, y } = touch;
      touches.current[id] = {
        ...touchPrev,
        xPrev,
        yPrev,
        ...touch,
      };
      const { distance, angle } = calculateMove(xStart, yStart, x, y);
      if (distance > moveThreshold) {
        onTouchMove(e, distance, angle);
        touches.current[id].moved = true;
        touches.current[id].xStart = x;
        touches.current[id].yStart = y;
      }
    }
  }, [onTouchMove]);

  const handleTouchEnd = useCallback((e) => {
    const { changedTouches } = e;
    for (let i = 0; i < changedTouches.length; i++) {
      const touch = getTouchData(changedTouches[i]);
      const { id } = touch;
      const touchPrev = touches.current[id] || {};
      delete touches.current[id];
      if (!touchPrev.moved) {
        onTouchEnd(e, touch);
      }
    }
  }, [onTouchEnd]);

  useLayoutEffect(() => {
    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchStart, handleTouchMove, handleTouchEnd]);
}

export function useDocumentClick(callback, dependencies = [callback]) {
  useLayoutEffect(() => {
    document.addEventListener('click', callback);
    return () => {
      document.removeEventListener('click', callback);
    };
  }, dependencies);
}

export function useProduceReducer(initialState) {
  return useReducer(
    (prevState, nextState) => produce(prevState, draft => ({ ...draft, ...nextState })),
    initialState,
  );
}
