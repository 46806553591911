import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useDebouncedCallback } from '../lib/hooks';
import OffscreenCanvas from '../lib/OffscreenCanvas';

export function setImageSmoothingEnabled(ctx, imageSmoothingEnabled) {
  if (ctx) {
    /* eslint-disable no-unused-expressions, no-param-reassign */
    !('imageSmoothingEnabled' in ctx) || (ctx.imageSmoothingEnabled = imageSmoothingEnabled);
    !('oImageSmoothingEnabled' in ctx) || (ctx.oImageSmoothingEnabled = imageSmoothingEnabled);
    !('msImageSmoothingEnabled' in ctx) || (ctx.msImageSmoothingEnabled = imageSmoothingEnabled);
    !('mozImageSmoothingEnabled' in ctx) || (ctx.mozImageSmoothingEnabled = imageSmoothingEnabled);
    !('webkitImageSmoothingEnabled' in ctx) || (ctx.webkitImageSmoothingEnabled = imageSmoothingEnabled);
    /* eslint-enable no-unused-expressions, no-param-reassign */
  }
}

const Canvas = forwardRef(({
  alpha,
  height,
  imageData,
  imageSmoothingEnabled,
  scale,
  style,
  useDevicePixelRatio,
  width,
  ...restProps
}, ref) => {
  const canvasRef = useRef(null);

  const stateRef = useRef({
    ctx: null,
    shadowCtx: null,
    prevImageData: null,
  });
  window.stateRef = stateRef;

  // Update 2D context
  useLayoutEffect(() => {
    if (canvasRef.current) {
      // Create shadow context
      const shadowCanvas = new OffscreenCanvas(width, height);
      const shadowCtx = shadowCanvas.getContext('2d', { alpha });
      setImageSmoothingEnabled(shadowCtx, false);

      // Stash previous image data
      const { shadowCtx: prevShadowCtx } = stateRef.current;
      if (prevShadowCtx) {
        const { width: prevWidth, height: prevHeight } = prevShadowCtx.canvas;
        const prevImageData = prevShadowCtx.getImageData(0, 0, prevWidth, prevHeight);
        for (let x = 0; x < width; x += prevWidth) {
          shadowCtx.putImageData(prevImageData, x, 0);
        }
      }

      stateRef.current.ctx = canvasRef.current.getContext('2d', { alpha });
      stateRef.current.shadowCtx = shadowCtx;
    }
  }, [width, height, alpha]);

  // @TODO: Listen for changes using matchMedia or similar
  const [pixelRatio] = useState(window.devicePixelRatio || 1);

  const imageDataScale = useMemo(
    () => scale * (useDevicePixelRatio ? (pixelRatio) : 1),
    [pixelRatio, scale, useDevicePixelRatio],
  );

  const drawFromShadowCanvas = useCallback(() => {
    const { ctx, shadowCtx } = stateRef.current;
    if (ctx && shadowCtx) {
      if (imageData) {
        shadowCtx.putImageData(imageData, 0, 0);
      }
      setImageSmoothingEnabled(ctx, false);
      if (alpha) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      }
      const drawWidth = ctx.canvas.width;
      const drawHeight = ctx.canvas.height;
      ctx.drawImage(shadowCtx.canvas, 0, 0, drawWidth, drawHeight);
      setImageSmoothingEnabled(ctx, imageSmoothingEnabled);
    }
  }, [imageDataScale]);
  const debouncedDrawFromShadowCanvas = useDebouncedCallback(drawFromShadowCanvas, 0);

  useImperativeHandle(ref, () => ({
    toDataURL(...args) {
      return stateRef.current.shadowCtx.canvas.toDataURL(...args);
    },
    scaledToDataURL(...args) {
      return canvasRef.current.toDataURL(...args);
    },
    clear(fillColor = null) {
      const { shadowCtx } = stateRef.current;
      const { canvas: shadowCanvas } = shadowCtx;
      let result;
      if (fillColor) {
        shadowCtx.fillStyle = fillColor;
        result = shadowCtx.fillRect(0, 0, shadowCanvas.width, shadowCanvas.height);
      } else {
        result = shadowCtx.clearRect(0, 0, shadowCanvas.width, shadowCanvas.height);
      }
      debouncedDrawFromShadowCanvas();
      return result;
    },
    drawImage(...args) {
      const result = stateRef.current.shadowCtx.drawImage(...args);
      debouncedDrawFromShadowCanvas();
      return result;
    },
    putImageData(...args) {
      const result = stateRef.current.shadowCtx.putImageData(...args);
      debouncedDrawFromShadowCanvas();
      return result;
    },
    clearRect(x, y, w, h) {
      const result = stateRef.current.shadowCtx.clearRect(x, y, w, h);
      debouncedDrawFromShadowCanvas();
      return result;
    },
    fillRect(x, y, w, h, fillColor = null) {
      if (fillColor) {
        stateRef.current.shadowCtx.fillStyle = fillColor;
      }
      const result = stateRef.current.shadowCtx.fillRect(x, y, w, h, fillColor);
      debouncedDrawFromShadowCanvas();
      return result;
    },
  }), [debouncedDrawFromShadowCanvas]);

  // Draw canvas when the imageData changes
  useLayoutEffect(
    drawFromShadowCanvas,
    [imageDataScale, imageSmoothingEnabled, scale, width, height, imageData],
  );

  const canvasStyle = imageDataScale === 1 ? style : {
    width: width * scale,
    height: height * scale,
    ...style,
  };

  return (
    <canvas
      {...restProps}
      width={width * imageDataScale}
      height={height * imageDataScale}
      ref={canvasRef}
      style={canvasStyle}
    />
  );
});

Canvas.propTypes = {
  alpha: PropTypes.bool,
  height: PropTypes.number.isRequired,
  imageData: PropTypes.instanceOf(ImageData),
  imageSmoothingEnabled: PropTypes.bool,
  scale: PropTypes.number,
  style: PropTypes.shape({}),
  useDevicePixelRatio: PropTypes.bool,
  width: PropTypes.number.isRequired,
};

Canvas.defaultProps = {
  alpha: true,
  imageData: null,
  imageSmoothingEnabled: false,
  scale: 1,
  style: {},
  useDevicePixelRatio: true,
};

export default Canvas;
