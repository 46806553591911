import OffscreenCanvas from './OffscreenCanvas';

export const NUM_BITPLANES = 2;
export const TILE_SIZE = 8;
export const BITS_PER_TILE = TILE_SIZE * TILE_SIZE * NUM_BITPLANES;
export const BYTES_PER_TILE = BITS_PER_TILE >>> 3;
export const COLORS_PER_SUBPALETTE = NUM_BITPLANES << 1;

export const FLIP_VERTICAL = 0b10000000;
export const FLIP_HORIZONTAL = 0b01000000;
export const BACKGROUND_PRIORITY = 0b00100000;
export const TRANSPARENT_BACKGROUND = 0b00010000;

const TILE_PIXEL_DATA_ROW_LENGTH = 4 * TILE_SIZE;

const NES_PALETTE = {
  [0x00]: 0x7c7c7c,
  [0x01]: 0x0923f8,
  [0x02]: 0x0417b9,
  [0x03]: 0x4430b9,
  [0x04]: 0x920f82,
  [0x05]: 0xa60424,
  [0x06]: 0xa6120d,
  [0x07]: 0x861508,
  [0x08]: 0x4f2f04,
  [0x09]: 0x0c770f,
  [0x0a]: 0x09670b,
  [0x0b]: 0x065707,
  [0x0c]: 0x034057,
  [0x0d]: 0x000000,
  [0x0e]: 0x000000,
  [0x0f]: 0x000000, // canonical black
  [0x10]: 0xbcbcbc,
  [0x11]: 0x147cf5,
  [0x12]: 0x0f5ef4,
  [0x13]: 0x684df8,
  [0x14]: 0xd61eca,
  [0x15]: 0xe20e5a,
  [0x16]: 0xf53a1b,
  [0x17]: 0xe25c22,
  [0x18]: 0xab7b19,
  [0x19]: 0x1ab61e,
  [0x1a]: 0x17a61a,
  [0x1b]: 0x17a749,
  [0x1c]: 0x128887,
  [0x1d]: 0x000000,
  [0x1e]: 0x000000,
  [0x1f]: 0x000000,
  [0x20]: 0xf8f8f8,
  [0x21]: 0x44bdfa,
  [0x22]: 0x6a8bf9,
  [0x23]: 0x987cf5,
  [0x24]: 0xf67df6,
  [0x25]: 0xf65b98,
  [0x26]: 0xf6785d,
  [0x27]: 0xfa9f4e,
  [0x28]: 0xf7b72a,
  [0x29]: 0xbaf638,
  [0x2a]: 0x5dd65b,
  [0x2b]: 0x60f69b,
  [0x2c]: 0x27e7d8,
  [0x2d]: 0x787878,
  [0x2e]: 0x000000,
  [0x2f]: 0x000000,
  [0x30]: 0xfcfcfc,
  [0x31]: 0xa6e4fb,
  [0x32]: 0xb8b9f6,
  [0x33]: 0xd8baf6,
  [0x34]: 0xf7baf7,
  [0x35]: 0xf7a5c0,
  [0x36]: 0xefd0b2,
  [0x37]: 0xfbdfab,
  [0x38]: 0xf7d77e,
  [0x39]: 0xd9f680,
  [0x3a]: 0xbaf7ba,
  [0x3b]: 0xbaf7d9,
  [0x3c]: 0x2cfcfb,
  [0x3d]: 0xd8d8d8,
  [0x3e]: 0x000000,
  [0x3f]: 0x000000,
};

export function getHexColor(index) {
  const color = NES_PALETTE[index] || 0;
  return `#${color.toString(16).padStart(6, '0')}`;
}

export const NES_PALETTE_RGB = new Uint8ClampedArray(0x400).fill(0xff);
for (let index = 0; index < 0x100; index++) {
  const color = NES_PALETTE[index] || 0;
  NES_PALETTE_RGB.set([
    (color & 0xff0000) >> 16,
    (color & 0x00ff00) >> 8,
    (color & 0x0000ff),
  ], index * 4);
}

const REVERSE_NYBBLE = [
  0b0000,
  0b1000,
  0b0100,
  0b1100,
  0b0010,
  0b1010,
  0b0110,
  0b1110,
  0b0001,
  0b1001,
  0b0101,
  0b1101,
  0b0011,
  0b1011,
  0b0111,
  0b1111,
];

const INVERT_TILE_OFFSET = [
  0x7,
  0x6,
  0x5,
  0x4,
  0x3,
  0x2,
  0x1,
  0x0,
  0xf,
  0xe,
  0xd,
  0xc,
  0xb,
  0xa,
  0x9,
  0x8,
];

export function mirrorTileDataHorizontal(srcData) {
  const chrData = new Uint8ClampedArray(srcData.length);
  const numTiles = Math.floor(srcData.length / BYTES_PER_TILE);
  for (let i = 0; i < chrData.length; i++) {
    const destTile = Math.floor(i / BYTES_PER_TILE);
    const srcTile = Math.max(0, (numTiles - destTile - (2 - 2 * (destTile % 2))) * BYTES_PER_TILE);
    const srcByte = i % BYTES_PER_TILE;
    const srcValue = srcData[srcTile + srcByte];
    const destValue = (REVERSE_NYBBLE[srcValue & 0xf] << 4) | (REVERSE_NYBBLE[srcValue >> 4]);
    chrData[i] = destValue;
  }
  return chrData;
}

export function mirrorTileDataVertical(srcData) {
  const chrData = new Uint8ClampedArray(srcData.length);
  const numTiles = Math.floor(srcData.length / BYTES_PER_TILE);
  for (let i = 0; i < chrData.length; i++) {
    const destTile = Math.floor(i / BYTES_PER_TILE);
    const srcTile = numTiles % 2 === 0
      ? Math.max(0, (destTile + (1 - 2 * (destTile % 2))) * BYTES_PER_TILE)
      : destTile * BYTES_PER_TILE;
    const srcByte = INVERT_TILE_OFFSET[i % BYTES_PER_TILE];
    const destValue = srcData[srcTile + srcByte];
    chrData[i] = destValue;
  }
  return chrData;
}

export function getTileData(index, chrData) {
  const tileStartByte = index * BYTES_PER_TILE;
  const tileEndByte = tileStartByte + BYTES_PER_TILE;
  return chrData.slice(tileStartByte, tileEndByte);
}

export function tileDataToPixelData(tileData, rgbPalette) {
  const pixelData = new Uint8ClampedArray(4 * TILE_SIZE * TILE_SIZE);
  for (let i = 0; i < pixelData.length; i += 4) {
    const pixel = i >>> 2;
    const pixelByteOffset = Math.floor(pixel / TILE_SIZE);
    const pixelBitOffset = TILE_SIZE - 1 - (pixel % TILE_SIZE);
    let color = 0;
    for (let k = NUM_BITPLANES - 1; k >= 0; k -= 1) {
      color = (color << 1) | (
        (tileData[pixelByteOffset + (k * TILE_SIZE)] >>> pixelBitOffset) & 0b1
      );
    }
    color >>>= 0;
    pixelData.set([
      rgbPalette[color * 4],
      rgbPalette[(color * 4) + 1],
      rgbPalette[(color * 4) + 2],
      rgbPalette[(color * 4) + 3],
    ], i);
  }
  return pixelData;
}

export function getSubpaletteRgb(c0, c1, c2, c3, palette = NES_PALETTE_RGB) {
  const color0 = c0 == null ? [0, 0, 0, 0] : palette.slice(c0 * 4, (c0 * 4) + 4);
  return [
    ...color0,
    ...palette.slice(c1 * 4, (c1 * 4) + 4),
    ...palette.slice(c2 * 4, (c2 * 4) + 4),
    ...palette.slice(c3 * 4, (c3 * 4) + 4),
  ];
}

export function getChrPixelData(index, c0, c1, c2, c3, attrs, chrData, palette) {
  let tileData = getTileData(index, chrData);
  if (attrs & FLIP_HORIZONTAL) {
    tileData = mirrorTileDataHorizontal(tileData);
  }
  if (attrs & FLIP_VERTICAL) {
    tileData = mirrorTileDataVertical(tileData);
  }
  const rgbPalette = getSubpaletteRgb(
    (attrs & TRANSPARENT_BACKGROUND) ? null : c0,
    c1,
    c2,
    c3,
    palette,
  );
  return tileDataToPixelData(tileData, rgbPalette);
}

export function writeTilePixelData(tilePixelData, x, y, pixelData, width) {
  const pixelDataRowLength = width * 4;
  const { length } = tilePixelData;
  let destOffset = (x * 4) + (y * pixelDataRowLength);
  for (let i = 0; i < length; i += TILE_PIXEL_DATA_ROW_LENGTH) {
    pixelData.set(tilePixelData.subarray(i, i + TILE_PIXEL_DATA_ROW_LENGTH), destOffset);
    destOffset += pixelDataRowLength;
  }
}

export function createChrDataCanvas(chrData, palette) {
  // 4096 makes the canvas too wide!
  const numTiles = Math.min(4095, Math.floor(chrData.length / BYTES_PER_TILE));
  const numSubpalettes = Math.floor(palette.length / COLORS_PER_SUBPALETTE);

  const width = numTiles * TILE_SIZE;
  const height = numSubpalettes * TILE_SIZE;

  const canvas = new OffscreenCanvas(width, height);
  const ctx = canvas.getContext('2d');
  const imageData = ctx.createImageData(width, height);

  const allTileData = new Array(numTiles).fill().map((v, i) => getTileData(i, chrData));

  for (let subpaletteIndex = 0; subpaletteIndex < numSubpalettes; subpaletteIndex++) {
    const paletteOffset = subpaletteIndex * 4;
    const c0 = palette[paletteOffset];
    const c1 = palette[paletteOffset + 1];
    const c2 = palette[paletteOffset + 2];
    const c3 = palette[paletteOffset + 3];
    const rgbPalette = getSubpaletteRgb(c0, c1, c2, c3);

    const y = TILE_SIZE * subpaletteIndex;
    allTileData.forEach((tileData, tileIndex) => {
      const x = TILE_SIZE * tileIndex;
      const tilePixelData = tileDataToPixelData(tileData, rgbPalette);
      try {
        writeTilePixelData(tilePixelData, x, y, imageData.data, width);
      } catch (e) {
        console.error(e);
      }
    });

    ctx.putImageData(imageData, 0, 0);
  }

  return canvas;
}
