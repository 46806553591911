import pick from 'lodash/pick';

export const DEFAULT_SETTINGS = {
  interval: 1000 / 15,
  volume: 0,
};

export const LOCAL_STORAGE_KEY = 'cellularAutomata';

export const SAVE_SETTINGS_KEYS = [
  'automaton',
  'dataWrap',
  'rule',
  'interval',
  'volume',
];

export function loadSettings(defaultSettings = DEFAULT_SETTINGS, storageKey = LOCAL_STORAGE_KEY) {
  const settings = { ...defaultSettings };
  try {
    const savedSettings = JSON.parse(localStorage.getItem(storageKey));
    if (typeof savedSettings.timestamp === 'number' && Date.now() - savedSettings.timestamp > 7 * 24 * 60 * 60 * 1000) {
      throw new Error('Too old!');
    }

    Object.entries(pick(savedSettings, SAVE_SETTINGS_KEYS))
      .forEach(([key, value]) => {
        if (typeof defaultSettings[key] === typeof value) {
          settings[key] = value;
        }
      });
  } catch (e) {
    // No valid saved settings
  }
  return settings;
}

export function saveSettings(settings, storageKey = LOCAL_STORAGE_KEY) {
  try {
    localStorage.setItem(storageKey, JSON.stringify({
      ...pick(settings, SAVE_SETTINGS_KEYS),
      timestamp: Date.now(),
    }));
  } catch (e) {
    // ...
  }
}
