export const VALUE_OFF = 0x00000000;
export const VALUE_ON = 0xffffffff;
export const rrggbbaaValueToBit = v => (v === VALUE_OFF ? 0b0 : 0b1);
export const rrggbbaaBitToValue = b => (b ? VALUE_ON : VALUE_OFF);

export function createRule(number) {
  const ruleMap = number.toString(2)
    .padStart(8, '0')
    .split('')
    .reverse()
    .map(v => parseInt(v, 2));
  return ruleMap;
}

export function processDataRule(
  number,
  valueToBit = rrggbbaaValueToBit,
  bitToValue = rrggbbaaBitToValue,
  dataWrap = true,
) {
  const rules = createRule(number);
  const badValue = bitToValue(0b0);
  return (value, index, data) => {
    const width = data.length;
    const indexLeft = dataWrap
      ? ((index - 1 + width) % width)
      : (index - 1);
    const indexRight = dataWrap
      ? ((index + 1) % width)
      : (index + 1);
    const valueLeft = indexLeft >= 0 ? data[indexLeft] : badValue;
    const valueRight = indexRight < width ? data[indexRight] : badValue;
    const bitLeft = valueToBit(valueLeft);
    const bitPrev = valueToBit(value);
    const bitRight = valueToBit(valueRight);
    const pattern = (bitLeft << 2) | (bitPrev << 1) | (bitRight << 0);
    const bitNext = rules[pattern];
    return bitToValue(bitNext, bitPrev, value);
  };
}
