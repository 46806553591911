import smb1chr from '../assets/smb1.chr';
import {
  TILE_SIZE,
  createChrDataCanvas,
} from '../lib/chrData';

const SMB1_SUBPALETTES = [
  // Pipe
  0x22, 0x29, 0x19, 0x0f, // 0, green overworld
  0x22, 0x30, 0x00, 0x10, // 1, snow overworld
  0x22, 0x27, 0x16, 0x0f, // 2, orange overworld
  0x22, 0x15, 0x12, 0x25, // 3, underwater
  0x0f, 0x29, 0x19, 0x09, // 4, underground
  0x0f, 0x30, 0x00, 0x10, // 5, castle
  0x0f, 0x29, 0x19, 0x0f, // 6, green overworld (night)
  0x0f, 0x30, 0x00, 0x10, // 7, snow overworld (night)
  0x0f, 0x27, 0x16, 0x0f, // 8, orange overworld (night)
  // Brick
  0x22, 0x36, 0x17, 0x0f, // 9, overworld
  0x22, 0x3a, 0x1a, 0x0f, // 10, underwater
  0x0f, 0x3c, 0x1c, 0x0f, // 11, underground
  0x0f, 0x30, 0x10, 0x00, // 12, castle
  0x0f, 0x36, 0x17, 0x0f, // 13, overworld (night)
  // Water
  0x22, 0x30, 0x21, 0x0f, // 14, overworld
  0x22, 0x30, 0x12, 0x0f, // 15, underwater
  0x0f, 0x30, 0x21, 0x1c, // 16, underground
  0x0f, 0x30, 0x21, 0x00, // 17, castle
  0x0f, 0x30, 0x21, 0x0f, // 18, overworld (night)
  // Coin
  0x22, 0x27, 0x17, 0x0f, // 19, overworld
  0x22, 0x17, 0x17, 0x0f, // 20, overworld
  0x22, 0x07, 0x17, 0x0f, // 21, overworld
  0x22, 0x27, 0x12, 0x0f, // 22, underwater
  0x22, 0x17, 0x12, 0x0f, // 23, underwater
  0x22, 0x07, 0x12, 0x0f, // 24, underwater
  0x0f, 0x27, 0x17, 0x1c, // 25, underground
  0x0f, 0x17, 0x17, 0x1c, // 26, underground
  0x0f, 0x07, 0x17, 0x1c, // 27, underground
  0x0f, 0x27, 0x17, 0x0f, // 28, castle, overworld (night)
  0x0f, 0x17, 0x17, 0x0f, // 29, castle, overworld (night)
  0x0f, 0x07, 0x17, 0x0f, // 30, castle, overworld (night)
  // Player
  0x22, 0x16, 0x27, 0x18, // 31, Mario
  0x22, 0x30, 0x27, 0x19, // 32, Luigi
  0x22, 0x37, 0x27, 0x16, // 33, Fire Mario
  0x0f, 0x16, 0x27, 0x18, // 34, Mario (night)
  0x0f, 0x30, 0x27, 0x19, // 35, Luigi (night)
  0x0f, 0x37, 0x27, 0x16, // 36, Fire Mario (night)
  // Green sprites
  0x22, 0x19, 0x30, 0x27, // 37, overworld
  0x22, 0x10, 0x30, 0x27, // 38, underwater
  0x0f, 0x1c, 0x36, 0x17, // 39, underground, castle
  0x0f, 0x1a, 0x30, 0x27, // 40, bowser
  0x0f, 0x19, 0x30, 0x27, // 41, overworld (night)
  // Red sprites
  0x22, 0x16, 0x30, 0x27, // 42, overworld, underwater
  0x0f, 0x16, 0x30, 0x27, // 43, underground, castle, overworld (night)
  // Brown sprite
  0x22, 0x0f, 0x36, 0x17, // 44, overworld
  0x22, 0x0f, 0x30, 0x10, // 45, underwater
  0x0f, 0x0c, 0x3c, 0x1c, // 46, underground
  0x0f, 0x00, 0x30, 0x10, // 47, castle
  0x0f, 0x0f, 0x36, 0x17, // 48, overworld (night)
];

const GAME_PALETTES = [
  [0, 9, 14, 19, 31, 37, 42, 44], // green overworld
  [1, 9, 14, 19, 32, 37, 42, 44], // snow overworld
  [2, 9, 14, 19, 32, 37, 42, 44], // orange overworld
  [3, 10, 15, 22, 31, 38, 42, 45], // underwater
  [4, 11, 16, 26, 34, 39, 43, 46], // underground
  [5, 12, 17, 28, 36, 40, 43, 47], // castle
  [6, 12, 17, 28, 35, 41, 43, 48], // green overworld (night)
  [7, 12, 17, 28, 34, 41, 43, 48], // snow overworld (night)
  [8, 12, 17, 28, 35, 41, 43, 48], // orange overworld (night)
];

const COIN_CYCLE_PALETTES = [
  [19, 20, 21, 20, 19, 19], // green overworld
  [19, 20, 21, 20, 19, 19], // snow overworld
  [19, 20, 21, 20, 19, 19], // orange overworld
  [22, 23, 24, 23, 22, 22], // underwater
  [25, 26, 27, 26, 25, 25], // underground
  [28, 29, 30, 29, 28, 28], // castle
  [28, 29, 30, 29, 28, 28], // green overworld (night)
  [28, 29, 30, 29, 28, 28], // snow overworld (night)
  [28, 29, 30, 29, 28, 28], // orange overworld (night)
];

const COIN_SUBPALETTE_INDEX = 3;
const COIN_CYCLE_FRAMES = COIN_CYCLE_PALETTES[0].length;

// side (S): 1 = left, 0 = right
// tile (T): pattern table tile ID
// palette (P): game palette
// subpalette (p): subpalette offset
//                       xxPPPxppxxxxxxxxAAAAAAAAxxxxxxxS
const BITMASK_UNUSED = 0b11000100111111110000000011111110;
const BITMASK_SUBPALETTE_SIDE = 0b00000011000000000000000000000001;

const BACKGROUND_SUBPALETTE_3 = 0b00000011000000000000000000000000;

export function createNesChrAutomaton(
  chrData = smb1chr,
  allSubpalettes = SMB1_SUBPALETTES,
) {
  // State
  let gamePaletteIndex = 0;
  let coinCycleIndex = 0;

  function getPaletteOffset(palette = 0, subpalette = 0, coin = 0) {
    if (subpalette === COIN_SUBPALETTE_INDEX) {
      return COIN_CYCLE_PALETTES[palette][coin];
    }
    return GAME_PALETTES[palette][subpalette];
  }

  // Conversion between a display value and a elementary cellular automaton bit
  const bitToValue = (bit, bitPrev, valuePrev = 0) => {
    const palette = ((valuePrev || 0) >>> 27) & 0b111;
    let subpalette = ((valuePrev || 0) >>> 24) & 0b11;
    let tile = ((valuePrev || 0) >>> 8) & 0b11111111;
    const side = bit & 0b1;

    if (valuePrev != null) {
      subpalette = (subpalette + 1) % 0b100;
      tile = (tile + 1) % 0x100;
    }

    return (
      (valuePrev == null ? 0 : BITMASK_UNUSED & valuePrev)
      | ((palette & 0b111) << 27)
      | ((subpalette & 0b11) << 24)
      | ((tile & 0b11111111) << 8)
      | side
    );
  };
  const valueToBit = value => value & 0b1;

  // Create the tile canvas
  const tileCanvas = createChrDataCanvas(chrData, allSubpalettes);

  // Get the source tile from the canvas for a given value
  const getCanvasSource = (value) => {
    const palette = (value >>> 27) & 0b111;
    const subpalette = (value >>> 24) & 0b11;
    const tile = (value >>> 8) & 0b11111111;
    const side = value & 0b1;

    const x = (side ? 0 : 0b100000000) | tile;
    const y = getPaletteOffset(palette % GAME_PALETTES.length, subpalette + (side ? 4 : 0), coinCycleIndex);
    return [
      TILE_SIZE * x,
      TILE_SIZE * y,
      TILE_SIZE,
      TILE_SIZE,
    ];
  };

  // Compare two values to determine whether the tile should rerender
  const shouldRender = () => true;

  // Start/stop asynchronous behavior
  let gamePaletteCycleInterval;
  let coinPaletteCycleInterval;

  // Find all data using
  function updateCoinTiles({ data, queue }) {
    const queueSet = new Set(queue);
    for (let i = 0; i < data.length; i++) {
      if (!queueSet.has(i) && (data[i] & BITMASK_SUBPALETTE_SIDE) === BACKGROUND_SUBPALETTE_3) {
        queue.push(i);
      }
    }
  }

  // Seed data
  function updatePalette(palette, { data, row, width }) {
    const end = Math.min((row + 1) * width, data.length);
    for (let i = row * width; i < end; i++) {
      // eslint-disable-next-line no-param-reassign
      data[i] = (
        (data[i] & 0b11000111111111111111111111111111)
        | ((palette & 0b111) << 27)
      );
    }
  }

  const start = (stateRef) => {
    // Game palette cycle
    gamePaletteCycleInterval = setInterval(() => {
      gamePaletteIndex = (gamePaletteIndex + 1) % GAME_PALETTES.length;
      updatePalette(gamePaletteIndex, stateRef.current);
    }, 60000);

    // Coin palette cycle
    coinPaletteCycleInterval = setInterval(() => {
      const prevCoinPalette = COIN_CYCLE_PALETTES[gamePaletteIndex][coinCycleIndex];
      coinCycleIndex = (coinCycleIndex + 1) % COIN_CYCLE_FRAMES;
      const coinPalette = COIN_CYCLE_PALETTES[gamePaletteIndex][coinCycleIndex];
      if (prevCoinPalette !== coinPalette) {
        updateCoinTiles(stateRef.current);
      }
    }, 140);
  };

  const stop = () => {
    clearInterval(gamePaletteCycleInterval);
    clearInterval(coinPaletteCycleInterval);
  };

  const reset = (stateRef) => {
    updatePalette(gamePaletteIndex, stateRef.current);
  };

  const special = (stateRef) => {
    gamePaletteIndex = (gamePaletteIndex + 1) % GAME_PALETTES.length;
    updatePalette(gamePaletteIndex, stateRef.current);

    clearInterval(gamePaletteCycleInterval);
    gamePaletteCycleInterval = setInterval(() => {
      gamePaletteIndex = (gamePaletteIndex + 1) % GAME_PALETTES.length;
      updatePalette(gamePaletteIndex, stateRef.current);
    }, 60000);
  };

  return {
    viewportScale: 24,
    minSize: 8,
    maxSize: 32,
    bitToValue,
    valueToBit,
    tileCanvas,
    getCanvasSource,
    shouldRender,
    start,
    stop,
    reset,
    special,
  };
}

export default createNesChrAutomaton();
