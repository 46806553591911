import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import {
  AUTOMATON_OVERRIDE,
  AUTOMATON_PROCESS,
  AUTOMATON_RESET,
  AUTOMATON_RESIZE,
} from '../constants';

import { useRafInterval } from '../lib/hooks';
import { processDataRule } from '../lib/utils';
import OffscreenCanvas from '../lib/OffscreenCanvas';

import colorsAutomaton from '../automatons/colors';

import Canvas from './Canvas';

const MAX_FRAME_TIME = 12;

function createData(width, height, prevData, prevWidth = width, prevHeight = height) {
  const data = new Uint32Array(width * height).fill(0);
  if (prevData) {
    const fillHeight = Math.min(height, prevHeight);
    const fillWidth = Math.min(width, prevWidth);
    for (let y = 0; y < fillHeight; y++) {
      const start = y * width;
      const prevStart = y * prevWidth;
      const prevRow = prevData.subarray(prevStart, prevStart + fillWidth);
      for (let x = 0; x < width; x += prevWidth) {
        const rowRemaining = width - x;
        if (fillWidth > rowRemaining) {
          data.set(prevRow.subarray(0, rowRemaining), start + x);
        } else {
          data.set(prevRow, start + x);
        }
      }
    }
  }
  return data;
}

const CellularAutomaton = forwardRef(({
  automaton,
  canvasHeight,
  canvasWidth,
  className,
  dataWrap,
  drawInterval,
  gridHeight,
  gridWidth,
  maxFrameTime,
  onProcessRow,
  processInterval,
  rule,
}, ref) => {
  const canvasRef = useRef(null);
  const draw = useRafInterval(drawInterval);
  const process = useRafInterval(Math.max(drawInterval, processInterval));

  const processData = useMemo(
    () => processDataRule(rule, automaton.valueToBit, automaton.bitToValue, dataWrap),
    [rule, automaton, dataWrap],
  );

  const reducer = (prevState, action) => {
    const {
      data,
      processDataOverride,
      queue,
      row,
    } = prevState;

    switch (action.type) {
      case AUTOMATON_RESET: {
        if (action.clear) {
          canvasRef.current.clear();
        }
        data.fill(0);
        data[Math.floor(gridWidth / 2)] = 1;
        return {
          ...prevState,
          row: 0,
          queue: new Array(data.length).fill().map((v, i) => i),
        };
      }

      case AUTOMATON_RESIZE: {
        const nextData = createData(gridWidth, gridHeight, data, prevState.width, prevState.height);
        return {
          ...prevState,
          data: nextData,
          row: row % gridHeight,
          queue: new Array(nextData.length).fill().map((v, i) => i),
          width: gridWidth,
          height: gridHeight,
        };
      }

      case AUTOMATON_OVERRIDE: {
        return {
          ...prevState,
          processDataOverride: action.processData || processDataOverride,
          row: action.row != null ? action.row : row,
        };
      }

      case AUTOMATON_PROCESS: {
        const processValue = processDataOverride || processData;

        const srcRow = (row % gridHeight);
        const destRow = (srcRow + 1) % gridHeight;

        const src = srcRow * gridWidth;
        const dest = destRow * gridWidth;

        const srcRowData = data.subarray(src, src + gridWidth);
        const prevRowData = data.slice(dest, dest + gridWidth);
        const destRowData = srcRowData.map((value, i) => processValue(value, i, srcRowData));

        const updateQueue = new Array(destRowData.length).fill().map((v, i) => (dest + i));
        queue.push(...updateQueue);
        data.set(destRowData, dest);

        if (typeof onProcessRow === 'function') {
          setTimeout(() => {
            onProcessRow(destRowData, prevRowData, destRow, data);
          }, 0);
        }

        return {
          ...prevState,
          row: destRow,
          processDataOverride: null,
        };
      }

      default:
        return prevState;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    data: createData(gridWidth, gridHeight),
    queue: [],
    row: 0,
    width: gridWidth,
    height: gridHeight,
  });

  const stateRef = useRef(state);
  useLayoutEffect(() => {
    stateRef.current = state;
  }, [state]);

  // Imperative interface
  useImperativeHandle(ref, () => ({
    reset(clear = false) {
      dispatch({ type: AUTOMATON_RESET, clear });
      if (typeof automaton.reset === 'function') {
        automaton.reset(stateRef);
      }
    },
    randomRow() {
      dispatch({
        type: AUTOMATON_OVERRIDE,
        processData: value => automaton.bitToValue(
          Math.round(Math.random()),
          Math.round(Math.random()),
          value,
        ),
      });
    },
    seedRow() {
      dispatch({
        type: AUTOMATON_OVERRIDE,
        processData: (value, i, rowData) => (i === Math.floor(rowData.length / 2)
          ? automaton.bitToValue(1, 0, value)
          : automaton.bitToValue(0, 1, value)
        ),
      });
    },
    clearRow() {
      dispatch({
        type: AUTOMATON_OVERRIDE,
        processData: value => automaton.bitToValue(0, 1, value),
      });
    },
    fillRow() {
      dispatch({
        type: AUTOMATON_OVERRIDE,
        processData: value => automaton.bitToValue(1, 0, value),
      });
    },
    special() {
      if (typeof automaton.special === 'function') {
        automaton.special(stateRef);
      }
    },
  }), [automaton]);

  // Resizing the cell grid regenerates the data
  useEffect(() => {
    dispatch({ type: AUTOMATON_RESIZE });
  }, [gridWidth, gridHeight]);

  // Calculate the tile size
  const { tileWidth, tileHeight } = useMemo(() => ({
    tileWidth: Math.ceil(canvasWidth / gridWidth),
    tileHeight: Math.ceil(canvasHeight / gridHeight),
  }), [canvasWidth, canvasHeight, gridWidth, gridHeight]);

  // Generate the next row
  useEffect(() => {
    dispatch({ type: AUTOMATON_PROCESS });
  }, [process]);

  // Render the updated tiles
  useEffect(() => {
    const {
      data,
      queue,
      width,
    } = stateRef.current;

    const {
      tileCanvas,
      getCanvasSource,
      shouldRender,
    } = automaton;

    const startTime = maxFrameTime && window.performance.now();
    let elapsedTime = 0;
    while (queue.length && maxFrameTime && elapsedTime < maxFrameTime) {
      const i = queue.shift();
      const value = data[i];
      if (typeof shouldRender !== 'function' || shouldRender(value)) {
        const x = i % width;
        const y = Math.floor(i / width);
        canvasRef.current.drawImage(
          tileCanvas,
          ...getCanvasSource(value),
          tileWidth * x, tileHeight * y, tileWidth, tileHeight,
        );
      }
      if (maxFrameTime && queue.length % 1000 === 0) {
        elapsedTime = window.performance.now() - startTime;
      }
    }
  }, [draw, automaton, tileWidth, tileHeight]);

  // Start or stop the automaton
  useEffect(() => {
    dispatch({ type: AUTOMATON_RESET, clear: true });

    if (typeof automaton.start === 'function') {
      automaton.start(stateRef);
    }

    return () => {
      if (typeof automaton.stop === 'function') {
        automaton.stop(stateRef);
      }
    };
  }, [automaton]);

  return (
    <Canvas
      className={className}
      ref={canvasRef}
      width={canvasWidth}
      height={canvasHeight}
    />
  );
});

CellularAutomaton.propTypes = {
  automaton: PropTypes.shape({
    bitToValue: PropTypes.func.isRequired,
    valueToBit: PropTypes.func.isRequired,
    tileCanvas: PropTypes.oneOfType([
      PropTypes.instanceOf(OffscreenCanvas),
      PropTypes.instanceOf(HTMLCanvasElement),
    ]).isRequired,
    getCanvasSource: PropTypes.func.isRequired,
    shouldRender: PropTypes.func,
    start: PropTypes.func,
    stop: PropTypes.func,
  }),
  canvasHeight: PropTypes.number.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  className: PropTypes.string,
  dataWrap: PropTypes.bool,
  drawInterval: PropTypes.number,
  gridHeight: PropTypes.number.isRequired,
  gridWidth: PropTypes.number.isRequired,
  maxFrameTime: PropTypes.number,
  onProcessRow: PropTypes.func,
  processInterval: PropTypes.number,
  rule: PropTypes.number,
};

CellularAutomaton.defaultProps = {
  automaton: colorsAutomaton,
  className: '',
  dataWrap: false,
  drawInterval: 1000 / 60,
  maxFrameTime: MAX_FRAME_TIME,
  onProcessRow: undefined,
  processInterval: 100,
  rule: 0,
};

export default CellularAutomaton;
