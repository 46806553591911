import pick from 'lodash/pick';
import values from 'lodash/values';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { render } from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import {
  KEY_1,
  KEY_2,
  KEY_LEFT,
  KEY_RIGHT,
  KEY_UP,
  KEY_DOWN,
  KEY_SPACE,
  KEY_ESCAPE,
  KEY_RETURN,
  KEY_TAB,
} from 'keycode-js';

import {
  useDocumentClick,
  useFirstTouch,
  useKeyCommands,
  useProduceReducer,
  useTouchEvents,
  useWindowSize,
} from './lib/hooks';
import {
  SAVE_SETTINGS_KEYS,
  loadSettings,
  saveSettings,
} from './lib/settings';
import ToneGenerator from './lib/ToneGenerator';

import colorsAutomaton from './automatons/colors';
import nesChrAutomaton from './automatons/neschr';

import CellularAutomaton from './components/CellularAutomaton';
import Message from './components/Message';

import { sayHello } from './console';

const BUTTON_COLUMNS = 3;
const BUTTON_ROWS = 3;
const BUTTON_ACTIONS = [
  'toggleMute',
  'reset',
  'useNextAutomaton',
  'previousRule',
  'rattle',
  'nextRule',
  'previousRule',
  'special',
  'nextRule',
];

const AUTOMATON_COLORS = 'COLORS';
const AUTOMATON_NES_CHR = 'NES_CHR';
const AUTOMATONS = {
  [AUTOMATON_COLORS]: colorsAutomaton,
  [AUTOMATON_NES_CHR]: nesChrAutomaton,
};
const AUTOMATONS_ORDER = [AUTOMATON_COLORS, AUTOMATON_NES_CHR];

const isPhoneMaybe = () => /phone/i.test(navigator.userAgent) || window.orientation != null;

const getCellPreferredSize = (
  viewportWidth,
  viewportHeight,
  viewportScale = 16,
  minSize = 2,
  maxSize = 20,
) => (
  Math.min(Math.max(minSize, (Math.min(viewportWidth, viewportHeight) / viewportScale)), maxSize)
);

const getCellHeight = (
  viewportWidth,
  viewportHeight,
  viewportScale = 16,
  minSize = 2,
  maxSize = 20,
) => {
  const cellSize = getCellPreferredSize(
    viewportWidth,
    viewportHeight,
    viewportScale,
    minSize,
    maxSize,
  );
  return isPhoneMaybe()
    ? viewportHeight / Math.ceil(viewportHeight / cellSize)
    : cellSize;
};

const getCellWidth = (
  viewportWidth,
  viewportHeight,
  viewportScale = 16,
  minSize = 2,
  maxSize = 20,
) => {
  const cellSize = getCellPreferredSize(
    viewportWidth,
    viewportHeight,
    viewportScale,
    minSize,
    maxSize,
  );
  return isPhoneMaybe()
    ? viewportWidth / Math.ceil(viewportWidth / cellSize)
    : cellSize;
};

const randomRattleInterval = () => Math.floor(Math.random() * 50 + 50);

const GlobalStyle = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  body {
    background-color: #000000;
    overflow: hidden;
  }
  ${Message} {
    position: fixed;
    bottom: 16px;
    left: 16px;
    padding: 2px 8px;
    font-family: monospace;
    font-size: 16px;
    line-height: 1.1;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.8);
  }
`;

const App = () => {
  const automatonRef = useRef(null);
  const messageRef = useRef(null);

  const logMessage = useCallback((message) => {
    if (messageRef.current) {
      messageRef.current.setMessage(message);
    }
  }, []);

  const [state, setState] = useProduceReducer({
    automaton: AUTOMATON_COLORS,
    dataWrap: false,
    frequency: null,
    rattle: randomRattleInterval(),
    rule: Math.floor(Math.random() * 0x100),
    interval: 100,
    volume: 0,
  });

  const stateRef = useRef(state);
  useLayoutEffect(() => {
    stateRef.current = state;
  }, [state]);

  useLayoutEffect(() => {
    setState(loadSettings({
      ...stateRef.current,
      volume: 100,
    }));
  }, []);

  const getState = useMemo(() => key => stateRef.current[key], []);

  const automaton = useMemo(
    () => (AUTOMATONS[state.automaton] || colorsAutomaton),
    [state.automaton],
  );

  useEffect(() => {
    const saveTimeout = setTimeout(() => {
      saveSettings(state);
    }, 250);
    return () => {
      clearTimeout(saveTimeout);
    };
  }, values(pick(state, SAVE_SETTINGS_KEYS)));

  const { width, height } = useWindowSize();

  const {
    gridWidth,
    gridHeight,
  } = useMemo(() => {
    const { viewportScale, minSize, maxSize } = automaton;
    const cellWidth = getCellWidth(width, height, viewportScale, minSize, maxSize);
    const cellHeight = getCellHeight(width, height, viewportScale, minSize, maxSize);
    return {
      gridWidth: Math.ceil(width / cellWidth),
      gridHeight: Math.ceil(height / cellHeight),
    };
  }, [width, height, automaton]);

  const actions = useMemo(() => ({
    useColorsAutomaton() {
      setState({ automaton: AUTOMATON_COLORS });
      logMessage('Automaton: Colors');
    },
    useNesChrAutomaton() {
      setState({ automaton: AUTOMATON_NES_CHR });
      logMessage('Automaton: NES CHR');
    },
    useNextAutomaton() {
      const automatonIndex = AUTOMATONS_ORDER.indexOf(getState('automaton'));
      const nextAutomaton = AUTOMATONS_ORDER[(automatonIndex + 1) % AUTOMATONS_ORDER.length];
      setState({ automaton: nextAutomaton });
    },
    previousRule() {
      setState({
        rattle: randomRattleInterval(),
        rule: (getState('rule') + 0xff) % 0x100,
      });
      logMessage(`Rule ${getState('rule')}`);
    },
    nextRule() {
      setState({
        rattle: randomRattleInterval(),
        rule: (getState('rule') + 0x01) % 0x100,
      });
      logMessage(`Rule ${getState('rule')}`);
    },
    decrementDelay10() {
      setState({ interval: Math.max(0, getState('interval') - 10) });
      logMessage(`Interval: ${getState('interval')}`);
    },
    incrementDelay10() {
      setState({ interval: Math.max(0, getState('interval') + 10) });
      logMessage(`Interval: ${getState('interval')}`);
    },
    decrementDelay() {
      setState({ interval: Math.max(0, getState('interval') - 1) });
      logMessage(`Interval: ${getState('interval')}`);
    },
    incrementDelay() {
      setState({ interval: Math.max(0, getState('interval') + 1) });
      logMessage(`Interval: ${getState('interval')}`);
    },
    rattle() {
      setState({
        dataWrap: Math.random() < 0.5,
        rattle: randomRattleInterval(),
        rule: Math.floor(Math.random() * 0x100),
      });
      automatonRef.current.randomRow();
    },
    randomRow() {
      setState({ rattle: randomRattleInterval() });
      automatonRef.current.randomRow();
    },
    seedRow() {
      setState({ rattle: randomRattleInterval() });
      automatonRef.current.seedRow();
    },
    fillRow() {
      setState({ rattle: randomRattleInterval() });
      automatonRef.current.fillRow();
    },
    clearRow() {
      setState({ rattle: randomRattleInterval() });
      automatonRef.current.clearRow();
    },
    mute() {
      setState({ volume: 0 });
      logMessage('Muted');
    },
    unmute() {
      setState({ volume: 100 });
      logMessage('Unmuted');
    },
    toggleMute() {
      setState({ volume: getState('volume') ? 0 : 100 });
      logMessage(getState('volume') ? 'Ummuted' : 'Muted');
    },
    special() {
      automatonRef.current.special();
    },
    reset() {
      setState({ rattle: randomRattleInterval() });
      automatonRef.current.reset();
    },
    toggleDataWrap() {
      setState({ dataWrap: !getState('dataWrap') });
      logMessage(`Wrap: ${getState('dataWrap')}`);
    },
  }), [logMessage]);

  useLayoutEffect(() => {
    window.interface = actions;
  }, [actions]);

  const toneGenerator = useMemo(() => new ToneGenerator(), []);
  useFirstTouch(() => {
    toneGenerator.resume();
    return toneGenerator.isReady();
  }, [toneGenerator]);

  const keyCommands = useMemo(() => ({
    [KEY_1]: () => actions.useColorsAutomaton(),
    [KEY_2]: () => actions.useNesChrAutomaton(),
    [KEY_LEFT]: () => actions.previousRule(),
    [KEY_RIGHT]: () => actions.nextRule(),
    [KEY_UP]: () => actions.decrementDelay10(),
    [KEY_DOWN]: () => actions.incrementDelay10(),
    [`shift+${KEY_UP}`]: () => actions.decrementDelay(),
    [`shift+${KEY_DOWN}`]: () => actions.incrementDelay(),
    [KEY_SPACE]: () => actions.randomRow(),
    [`alt+${KEY_SPACE}`]: () => actions.seedRow(),
    [`shift+${KEY_SPACE}`]: () => actions.fillRow(),
    [`alt+shift+${KEY_SPACE}`]: () => actions.clearRow(),
    [KEY_ESCAPE]: () => actions.toggleMute(),
    [`shift+${KEY_RETURN}`]: () => actions.special(),
    [KEY_RETURN]: () => {
      toneGenerator.resume();
      actions.reset();
    },
    [KEY_TAB]: () => actions.toggleDataWrap(),
  }), [actions, toneGenerator]);
  const preKeyCommand = useCallback((e) => {
    e.preventDefault();
    toneGenerator.resume();
  }, []);
  useKeyCommands(keyCommands, preKeyCommand);

  const handleTouchMove = useCallback((e, distance, angle) => {
    const roundedAngle = (360 + (Math.round(angle / 90) * 90)) % 360;
    switch (roundedAngle) {
      case 0:
        actions.decrementDelay10();
        break;
      case 90:
        actions.nextRule();
        break;
      case 180:
        actions.incrementDelay10();
        break;
      case 270:
        actions.previousRule();
        break;
      default:
        break;
    }
  }, [actions]);

  const getTouchAction = useCallback((touch) => {
    const { x, y } = touch;
    const column = Math.floor(x / (width / BUTTON_COLUMNS));
    const row = Math.floor(y / (height / BUTTON_ROWS));
    const i = column + (row * BUTTON_COLUMNS);
    return actions[BUTTON_ACTIONS[i]];
  }, [actions, width, height]);

  const handleTouchEnd = useCallback((e, touch) => {
    const action = getTouchAction(touch);
    if (typeof action === 'function') {
      action();
    }
  }, [getTouchAction]);

  useTouchEvents(handleTouchMove, handleTouchEnd);

  const handleClick = useCallback(() => {
    toneGenerator.resume();
    actions.rattle();
  }, [actions]);

  useDocumentClick(handleClick);

  const handleProcessRow = useCallback((rowData) => {
    // Shake things up every couple of rows
    const { rattle } = stateRef.current;
    if (rattle <= 0) {
      actions.rattle();
    } else {
      setState({ rattle: rattle - 1 });
    }

    // Also, generate a frequency if possible
    if (!toneGenerator.isReady()) {
      return;
    }

    const {
      frequency: prevFrequency,
      interval,
      volume,
    } = stateRef.current;

    const frequency = typeof automaton.generateFrequency === 'function'
      ? automaton.generateFrequency(rowData, prevFrequency)
      : (rowData
        .reduce((result, value) => (
          ((result + 1) * ((value % 6) + 1)) % 0xffff
        ), prevFrequency)
      );

    setState({ frequency });

    const audioVolume = volume / 1000;
    if (audioVolume) {
      const totalDuration = Math.max(10, interval); // much shorter and you can't really hear it...
      const attack = Math.floor(Math.min(0.01 * totalDuration, 10));
      const release = Math.floor(Math.min(0.05 * totalDuration, 100));
      const duration = totalDuration - release;
      toneGenerator.playNote(80 - (frequency % 60), audioVolume, duration, attack, release);
    }
  }, [actions, toneGenerator]);

  return (
    <>
      <Message ref={messageRef} />
      <CellularAutomaton
        automaton={automaton}
        canvasHeight={height}
        canvasWidth={width}
        dataWrap={state.dataWrap}
        gridHeight={gridHeight}
        gridWidth={gridWidth}
        ref={automatonRef}
        rule={state.rule}
        onProcessRow={handleProcessRow}
        processInterval={state.interval}
      />
    </>
  );
};

render(
  (
    <>
      <GlobalStyle />
      <App />
    </>
  ),
  document.getElementById('react-root'),
);

sayHello();
