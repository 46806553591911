let lastText;
export function log(text, color, styles) {
  if (color) {
    console.log(`%c${text}`, `color: ${color}; ${styles}`);
  } else {
    console.log(text);
  }
  lastText = text;
}

export function hr(char, color, styles) {
  log((new Array(lastText ? lastText.length : 1)).fill(char).join(''), color, styles);
}

export function sayHello() {
  log('Keith McKnight', '#444444', 'font-size: large; font-weight: bold');
  log('Engineering Manager', '#666666', 'font-size: medium');
  hr('-', '#cccccc', 'font-size: medium');
  log('https://github.com/kmck', '#33aadd', 'text-decoration: underline');
  log('https://linkedin.com/in/keithmcknight', '#33aadd', 'text-decoration: underline');
  hr('-', '#cccccc');
  log('🎨👽👍', 'inherit', 'font-size: xx-large');
  log('How do you do, fellow hackers?', '#00cc33');
}
