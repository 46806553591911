const AudioContext = window.AudioContext || window.webkitAudioContext;

// MIDI scale: note 60 is middle C
export const getFrequency = note => 440 * (2 ** ((note - 69) / 12));
export const getNote = frequency => 69 + (12 * Math.log2(frequency / 440));

export default function ToneGenerator() {
  if (!AudioContext) {
    console.warn('No AudioContext :(');
    return {
      isReady() {
        return false;
      },
      playFrequency() {},
      playNote() {},
      resume() {
        return false;
      },
    };
  }

  const audio = new AudioContext();

  function isReady() {
    return audio.state !== 'suspended';
  }

  function playFrequency(frequency, volume = 0.1, duration = 250, attack = 10, release = 100) {
    if (!isReady()) {
      return;
    }

    const totalDuration = duration + release;
    if (!totalDuration) {
      return;
    }

    const actualAttack = Math.min(attack, duration);
    const gainNode = audio.createGain();
    const oscillatorNode = audio.createOscillator();

    gainNode.connect(audio.destination);
    if (actualAttack) {
      gainNode.gain.setValueAtTime(0, audio.currentTime);
      gainNode.gain.linearRampToValueAtTime(volume, audio.currentTime + (actualAttack / 1000));
    } else {
      gainNode.gain.setValueAtTime(volume, audio.currentTime);
    }
    gainNode.gain.linearRampToValueAtTime(volume, audio.currentTime + (duration / 1000));
    if (release) {
      gainNode.gain.linearRampToValueAtTime(0, audio.currentTime + (totalDuration / 1000));
    }

    oscillatorNode.frequency.setValueAtTime(frequency, audio.currentTime);
    oscillatorNode.type = 'square';
    oscillatorNode.connect(gainNode);
    oscillatorNode.start(0);

    setTimeout(() => {
      oscillatorNode.stop(0);
      oscillatorNode.disconnect(gainNode);
      gainNode.disconnect(audio.destination);
    }, totalDuration);
  }

  function playNote(note, volume, duration, attack, release) {
    playFrequency(getFrequency(note), volume, duration, attack, release);
  }

  function resume() {
    audio.resume();
    return isReady();
  }

  return {
    isReady,
    playFrequency,
    playNote,
    resume,
  };
}
